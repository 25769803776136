import "./Header.css";
import Headercontent from "../../components/Headercontent/Headercontent";
import { useTypewriter, Cursor } from "react-simple-typewriter";

const Header = () => {
  const [typeEffect] = useTypewriter({
    words: ["Front-End Developer", "Product Designer", "Creative", "Yazan"],
    loop: true,
    typeSpeed: 100,
    deleteSpeed: 100,
    autoStartDelay: 1000,
  });

  return (
    <div className="Header">
      <div className="Wrapper">
        <Headercontent
          className="text-container"
          span="Heya!"
          header={
            <>
              I am <span className="span">{typeEffect}</span>
              <span style={{ color: "#C9DA2D" }}>
                <Cursor />
              </span>
            </>
          }
        />
      </div>
    </div>
  );
};

export default Header;
