import "./Skills.css";
import Sectiontitle from "../../components/Sectiontitle/Sectiontitle";
import Box from "../../components/SkillsBox/Box";
import { UIUXSkills } from "../../data/SkillsData";
import { frontEndSkills } from "../../data/SkillsData";

const Skills = () => {
  return (
    <div className="Skills hidden">
      <Sectiontitle h2="Services" className="skills-title" />

      <div className="Double-box">
        <Box
          className="Front-End-Development"
          h3="Front-End Development:"
          list={frontEndSkills}
        />
        <Box className="UI-UX" h3="Product Design:" list={UIUXSkills} />
      </div>
    </div>
  );
};

export default Skills;
