import React, { useState, useEffect } from "react";
import "./Work.css";
import Sectiontitle from "../../components/Sectiontitle/Sectiontitle";
import WorkData from "../../components/WorkData/WorkData";
import { uiUXDataSlider } from "../../data/uiUXDataSlider";
import { frontEndDevDataSlider } from "../../data/frontEndDevDataSlider";

const Work = () => {
  const [containerWidth, setContainerWidth] = useState("100%");
  const [containerHeight, setContainerHeight] = useState("100%");
  const [activeTitle, setActiveTitle] = useState(null);
  const [activeCategory, setActiveCategory] = useState(null);
  const [uiUXIndex, setUIUXIndex] = useState(uiUXDataSlider.length - 1);
  const [frontEndDevIndex, setFrontEndDevIndex] = useState(
    frontEndDevDataSlider.length - 1
  );

  const dataSlider = [
    {
      category: "UI/UX",
      data: [...uiUXDataSlider],
      index: uiUXIndex,
      setIndex: setUIUXIndex,
    },
    {
      category: "Front-End Development",
      data: [...frontEndDevDataSlider],
      index: frontEndDevIndex,
      setIndex: setFrontEndDevIndex,
    },
  ];

  const handleTitleClick = (title) => {
    const selectedCategory = dataSlider.find((item) => item.category === title);

    if (!selectedCategory) {
      return;
    }

    if (activeTitle === title) {
      return;
    }

    if (window.innerWidth > 1024 && containerWidth === "100%") {
      setContainerWidth("50%");
    }

    if (window.innerWidth <= 1024 && containerHeight === "100%") {
      setContainerHeight("80px");
    }

    setActiveTitle(title);

    if (containerWidth === "50%" || containerHeight === "80px") {
      setTimeout(() => {
        setActiveCategory(title);
      }, 1000);
    } else {
      setActiveCategory(title);
    }

    if (window.innerWidth > 1024) {
      handleContent();
    }
    if (window.innerWidth <= 1024) {
      handleHeight();
    }

    setTimeout(() => {
      selectedCategory.setIndex(selectedCategory.data.length - 1);
    }, 1000);
  };

  const handleContent = () => {
    if (window.innerWidth > 1024) {
      if (containerWidth === "50%") setContainerWidth("100%");

      setTimeout(() => {
        setContainerWidth("50%");
      }, 1000);
    }
  };

  const handleHeight = () => {
    if (window.innerWidth <= 1024) {
      if (containerHeight === "80px") setContainerHeight("100%");

      setTimeout(() => {
        setContainerHeight("80px");
      }, 1000);
    }
  };

  function showNextImage() {
    const selectedCategory = dataSlider.find(
      (item) => item.category === activeCategory
    );

    if (!selectedCategory) {
      return;
    }

    selectedCategory.setIndex((index) => {
      if (index === 0) return selectedCategory.data.length - 1;
      return index - 1;
    });
  }

  function showPrevImage() {
    const selectedCategory = dataSlider.find(
      (item) => item.category === activeCategory
    );

    if (!selectedCategory) {
      return;
    }

    selectedCategory.setIndex((index) => {
      if (index === selectedCategory.data.length - 1) return 0;
      return index + 1;
    });
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024 && containerWidth === "50%") {
        setContainerWidth("100%");
        setContainerHeight("80px");
      } else if (window.innerWidth > 1024 && containerHeight === "80px") {
        setContainerWidth("50%");
        setContainerHeight("100%");
      }
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [containerWidth, containerHeight]);

  const isWideScreen = window.innerWidth > 1024;

  return (
    <div className="Work hidden">
      <div className="Work-main">
        <Sectiontitle h2="Portfolio" className="portfolio-title" />
        <div
          style={{
            width: containerWidth,
            height: containerHeight,
          }}
          className="Work-container"
        >
          <div className="Work-container-content">
            <h2
              className={
                activeTitle === "Front-End Development" ? "active" : ""
              }
              onClick={() => handleTitleClick("Front-End Development")}
            >
              Front-End Development
            </h2>
            <span className="seperator">/</span>

            <h2
              className={activeTitle === "UI/UX" ? "active" : ""}
              onClick={() => handleTitleClick("UI/UX")}
            >
              Product Design
            </h2>
          </div>
        </div>

        <div className="InfoData">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            viewBox="0 0 512 512"
            className="upArrow"
            onClick={window.innerWidth > 1024 ? showNextImage : showPrevImage}
          >
            <path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM385 231c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-71-71V376c0 13.3-10.7 24-24 24s-24-10.7-24-24V193.9l-71 71c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9L239 119c9.4-9.4 24.6-9.4 33.9 0L385 231z" />
          </svg>
          {/* up arrow */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="40"
            width="40"
            viewBox="0 0 512 512"
            className="downArrow"
            onClick={window.innerWidth > 1024 ? showPrevImage : showNextImage}
          >
            <path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM127 281c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l71 71L232 136c0-13.3 10.7-24 24-24s24 10.7 24 24l0 182.1 71-71c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 393c-9.4 9.4-24.6 9.4-33.9 0L127 281z" />
          </svg>
          {/* down arrow */}
          <svg
            className="rightArrow"
            xmlns="http://www.w3.org/2000/svg"
            height="35"
            width="35"
            viewBox="0 0 512 512"
            onClick={window.innerWidth > 1024 ? showPrevImage : showNextImage}
          >
            <path d="M0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM281 385c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l71-71L136 280c-13.3 0-24-10.7-24-24s10.7-24 24-24l182.1 0-71-71c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L393 239c9.4 9.4 9.4 24.6 0 33.9L281 385z" />
          </svg>
          {/* right arrow */}
          <svg
            className="leftArrow"
            xmlns="http://www.w3.org/2000/svg"
            height="35"
            width="35"
            viewBox="0 0 512 512"
            onClick={window.innerWidth > 1024 ? showNextImage : showPrevImage}
          >
            <path d="M512 256A256 256 0 1 0 0 256a256 256 0 1 0 512 0zM231 127c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-71 71L376 232c13.3 0 24 10.7 24 24s-10.7 24-24 24l-182.1 0 71 71c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0L119 273c-9.4-9.4-9.4-24.6 0-33.9L231 127z" />
          </svg>
          {/* left arrow */}
          <div className="InfoDataSlider">
            {activeCategory &&
              dataSlider
                .find((category) => category.category === activeCategory)
                .data.map((el, index) => (
                  <WorkData
                    key={index}
                    data={el}
                    style={{
                      transform: isWideScreen
                        ? `translateY(${
                            -100 *
                            dataSlider.find(
                              (category) => category.category === activeCategory
                            ).index
                          }%)`
                        : `translateX(${
                            100 *
                            dataSlider.find(
                              (category) => category.category === activeCategory
                            ).index
                          }%)`,
                    }}
                  />
                ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
