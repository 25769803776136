// product design skills
export const UIUXSkills = [
  "User Experience (UX)",
  "User Interface (UI)",
  "User Research",
  "Information Architecture",
  "Wireframing & Prototyping",
  "Usability Testing",
  "Visual Design",
];

// frontend development skills
export const frontEndSkills = [
  "HTML & CSS",
  "JavaScript",
  "TypeScript",
  "React",
  "Redux Toolkit",
  "Tailwind CSS",
  "Git/Github",
];
