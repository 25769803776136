import image1 from "../../src/assets/images/frontEndDevDataSlider/image1.jpg";
import image2 from "../../src/assets/images/frontEndDevDataSlider/whosthesmartest.png";
import image3 from "../../src/assets/images/frontEndDevDataSlider/peaceTech.png";

export const frontEndDevDataSlider = [
  {
    id: "1",
    className: "frontEndDevDataSlider",
    src: image1,
    alt: "Europe Adventures",
    h2: "Europe Adventures",
    href: "https://github.com/Yazan-Egbaria/edventures-repo",
  },
  {
    id: "2",
    className: "frontEndDevDataSlider",
    src: image2,
    alt: "Who's The Smartest",
    h2: "Who's The Smartest",
    href: "https://github.com/Yazan-Egbaria/Who-Is-The-Smartest",
  },
  {
    id: "3",
    className: "frontEndDevDataSlider",
    src: image3,
    alt: "Peace Tech",
    h2: "Peace Tech",
    href: "https://github.com/Yazan-Egbaria/appleseeds-final-project",
  },
];
